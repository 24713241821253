html {
	font-size: 100%;
	min-height: calc(100% + env(safe-area-inset-top));
}
body {
	margin: 0;
}
a {
	color: var(--palette-primary-main);
	text-decoration: none;
}
p {
	margin: 0;
}
ul {
	list-style: inside;
	margin: 0;
	padding: 0;
}
*:not(.no-custom-scrollbar) {
	scrollbar-color: var(--palette-primary-main) transparent;
	scrollbar-width: thin;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar {
	width: 10px;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-track {
	background: transparent;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-thumb {
	background-color: var(--palette-primary-main);
	border: 0 solid transparent;
	border-radius: 0;
}

/* Custom Scrollbar */

.custom-scrollbar__view {
	margin-bottom: 0 !important;
	min-height: 100vh !important;
	overflow-x: hidden !important;
}
.MuiTypography-h1::before {
	display: none !important;
}
